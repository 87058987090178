
#experience {
    background-color: #3B4CA6;
    /*padding-bottom: 5rem;*/
}

.experience__container {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:2rem;
    
    
}

.experience__container  > div {
  /*background: var(--color-bg-white);*/
  background-color: #2E2779;
  padding-top:0rem;
  padding-bottom:1.5rem;
  border:  var(--separator-line);
  border-radius: 5%;
  transition:var(--transition);
 
 /* box-shadow: var(--feature-shadow);
-webkit-box-shadow: var(--feature-shadow);
-moz-box-shadow: var(--feature-shadow);*/
}


.experience__container  > div:hover {
    background-color:#2E2779;
    border-color:var(--color-primary-variant);
    
    cursor:default;
}

.experience__container > div h3 {
  text-align:center;
  margin-bottom: 2rem;
  color:var(--color-primary);
}

.experience__backend {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap:10px;
    scale:0.8;
   
}


.small-image {
 flex:2;
 position: relative;
 top:10%;
 

}

.large-desc {
    flex:5; 
}

.large-desc-img{
    position: relative; 
   /*height:50%;*/
   width:13rem;
   
}

.large-desc-img img {
    height:15rem; 
    width:13rem;
}



.large-desc-img::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 15rem;
    background-color: rgba(71, 103, 172, 0.4);
    border-radius: 10%;
  }

.small-image img {
    height:3rem;
   
}

.experience__content {
   display:grid;
   grid-template-columns: 1fr 1fr;
   row-gap:2rem;
}

.experience__details {
    display:flex;
    gap:1rem;

}

.experience__details-icon {
    margin-top: 6px;
    color:var(--color-primary);

}


.btn-pos {
   margin-top: 5%;
}




.experiencegrid__container {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
   
    padding-top: 1rem ; 
    padding-bottom: 0rem ; 
    height:2.5rem;
    
}


.experiencegrid__container  > div {
    background: var(--color-white);
    /*padding:2.4rem 5rem;*/
    
    /*border:  var(--separator-line);*/
   
    transition:var(--transition);
   
    box-shadow: var(--feature-shadow);
  -webkit-box-shadow: var(--feature-shadow);
  -moz-box-shadow: var(--feature-shadow);
  
  
  }


  .experiencegrid-iconContainer{
    color:var(--color-primary-variant);
    font-size: 2rem;
    background-color:/* var(--color-white)*/black;
    border-radius: 100px;
    /*box shape*/
    /*border: var(--separator-line);*/
    width: 70px;
    height: 70px;
   
     /*box shape*/

     /*box position*/
     margin:0 auto;
     position: relative;
     top:0%;
     left:0%;
     z-index:0;
     display: flex;
     justify-content: center;
     align-items: center;
     /*box position*/

    /*shading and shadows*/
     box-shadow: var(--feature-shadow);
     -webkit-box-shadow: var(--feature-shadow);
     -moz-box-shadow: var(--feature-shadow);
}

.experiencegrid-iconContainer img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    
}





.experiencegrid-iconContainer::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(71, 103, 172, 0.4);
    border-radius: 50%;
  }

/*============== MEDIA QUERIES (KINDA LARGE DEVICES) ======================= */

@media screen and (max-width:1500px) {
    .experience__container {
        grid-template-columns:1fr 1fr 1fr;

    }

   

    .large-desc-img{
        position: relative; 
       /*height:50%;*/
       width:11rem;
       
    }

    .large-desc-img img {
        height:13rem; 
        width:11rem;
    }



    .large-desc-img::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 13rem;
        background-color: rgba(71, 103, 172, 0.4);
        border-radius: 10%;
        
      }


        }


/*============== MEDIA QUERIES (MEDIUM DEVICES) ======================= */

@media screen and (max-width:1150px) {
  /* #experience{
    display:none;
   }*/

   .experiencegrid__container {
    grid-template-columns: 1fr 1fr 1fr  ;
    gap:1rem;
    margin-top: 1rem;
   
}


.experiencegrid__container {   
    height:7rem;
}


   
    .experience__container {
        grid-template-columns: 1fr;
    }



    .experience__container > div {
        width:50%;
        padding-bottom:2rem;
        margin:0 auto;
    }

    .experience__content {
        padding: 1rem;
    }

    .large-desc-img{
        position: relative; 
       /*height:50%;*/
       width:13rem;
       
    }

    .large-desc-img img {
        height:14rem; 
        width: 100%;
       
    }

    .large-desc-img::before {
        width: 100%;
        height: 14rem;
        
      }


}


/*============== MEDIA QUERIES (MEDIUM DEVICES) ======================= */
@media screen and (max-width:900px) {

    .experience__container > div {
        width:70%;
        padding-bottom:2rem;
        margin:0 auto;
    }

}


/*============== MEDIA QUERIES (SMALL DEVICES) ======================= */

@media screen and (max-width:600px) {
    .experience__container {
        
        gap: 1rem;

    }

    .experience__container > div {
        width:80%;
        padding-bottom:2rem;

    }


     /* ================= MEDIA QUERIES (SMALL DEVICES) ==================== */
 @media screen and (max-width:600px) {
 
    .experiencegrid__container {
          
          gap: 0.5rem;
          grid-template-columns: 1fr 1fr 1fr;
  
      }


      .experiencegrid__container {
      
        height:8.5rem;
        
    }

     
   }




}

 




